@import './../../../../scss/theme-bootstrap';

.back-to-top {
  width: 80px;
  height: 80px;
  text-align: center;
  right: 0;
  cursor: pointer;
  position: fixed;
  top: 45%;
  opacity: 0;
  box-shadow: 1px 0 16px $color-shadow;
  display: block;
  background: $color-white;
  padding: 21px 24px;
  line-height: 1.4em;
  letter-spacing: 0.2em;
  z-index: 101;
  color: $color-pink-accent;
  pointer-events: none;
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
  &__icon {
    fill: $color-pink-accent;
    height: 22px;
    width: 22px;
  }
  &__text {
    font-size: 13px;
    font-weight: 600;
  }
}
