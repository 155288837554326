///
/// @file global/_variables.scss
///
/// \brief Variables
///
/// These are universally needed files that are SCSS-specific. Most of the
/// following do not directly result in rendered CSS.
/// @setup change all for new brand

// Layout
$small-max-width: 960px !default;
$max-width: 1280px !default;
$max-width-medium: 1440px !default;
$max-width-large: 1920px !default;
$max-width-small-formatters: 960px !default;
$header-height: 150px !default; // used to offset anchors, etc.
$sticky-height: 56px;
$mobile-signup-block-height: 150px !default;
$signup-block-height: 120px !default;

$mobile-site-offer-banner-height: 56px;
$tablet-site-offer-banner-height: 56px;

$mobile-sticky-height: 50px + $mobile-site-offer-banner-height;
$tablet-sticky-height: 50px + $tablet-site-offer-banner-height;

$cta-button-min-height: 2.7rem !default;

$add-to-bag-button-height--mobile: 45px;
$add-to-bag-button-height--desktop: 55px;

// Typography
$base-font-size: 16px;
$base-line-height: 1.2 !default;
$base-header-line-height: 1.4 !default;

// Named Brand Colors - from styleguide
// text and accents
$color-pink-1: #fdc2cb;
$color-pink-2: #fff5f5;
$color-pink-hover: #f8d4d9;
$color-pink-button-text: #fee2e6;
$color-pink-accent: #db008b;
$color-pink-accent-hover: #e233a2;
$color-black: #000000;
$color-black-hover: #4d4d4d;
$color-gold: #bf9b5f;
$color-gold-hover: #ceb893;
$color-darkest-gold: #cea165;
$color-teal: #c6e8ea;
$color-yellow: #f4ecca;
$color-mint: #e3eccd;
$color-normal-grey: #cccccc !default;
$color-cornflower-blue: #3f79d1 !default;
$color-dark-green: #48703f;
$color-light-pink: #fdc2d8;
$color-dark-grey: #5b626a;
$color-medium-grey: #d6d6d6;
$color-light-black: #1a1a1a;

// Define $color-pink // primary pink
$color-pink: $color-pink-1;

// Colors (http://sachagreif.com/sass-color-variables/)
$black: #000 !default;
$white: #fff !default;
$color-black: $black !default;
$color-dark-gray: #404040;
$color-light-gray: #9c9797;
$color-white: $white !default;
$color-off-white: #f5f5f5;
$color-gray: #e6e6e6 !default;
$color-grey: $color-gray !default;
$color-light-gray: #b0b0b0 !default;
$color-light-grey: $color-light-gray !default;
$color-dark-gray: #1b1b1b !default;
$color-dark-grey: $color-dark-gray !default;
$color-lighter-gray: #c2c2c2 !default;
$color-lighter-grey: $color-lighter-gray !default;
$color-fb-blue: #4267b2 !default;
$color-pink-popup: #f55196 !default;
$color-lipfinder-pink: #e01288 !default;
$color-lipfinder-gold: #bb9f78 !default;
$color-lipfinder-pastel-gold: #bb9f78 !default;
$color-lipfinder-pastel-pink: #fbe3e8 !default;
$color-lipfinder-menu-pink: #ffe3e5 !default;
$color-lipfinder-dropshadow: #947a6d !default;
$color-lipfinder-off-white: #fcf2f2 !default;
$color-light-green: #39a339 !default;
$color-btn-grey: #333;

$color-gnav-background: #fee2e6;
$color-text: $color-black;
$color-link: $color-black !default;
$color-link-hover: $color-pink-accent !default;
$color-filter-box-border: #f5f5f5;
$color-pink-3: #ffe2e6 !default;
$color-disabled: #999999;

$color-green: #090 !default;
$color-red: #f00 !default;
// Foundation Finder colors
$color-ff-reco-pink-1: #fae5e6 !default;
$color-ff-reco-pink-2: #f5c8cc !default;

$color-ff-finish-box-shadow: #f8d1d5 !default;
$color-ff-finish-box-hover: #f2a2b0 !default;

$color-ff-result-box-shadow: #ececec !default;
$color-ff-result-info-bg: #f5f5f5 !default;
$color-ff-top-menu-shadow: rgba(168, 38, 0, 0.16) !default;
$color-ff-result-reco-bg: #f8f8f8 !default;

// outline styles on focused elements for keybard accessibility
$outline: $color-gold thin solid;

// frontend validation variables
$color-focus: $color-pink-1;
$color-valid: green;
$color-success: $color-valid;
$color-error: $color-pink-accent;
$color-invalid: $color-error;
$border-color-normal: $color-lighter-gray;
$border-color-error: $color-error;
$icon-background: white;
$inline-error-background: white;

$color-border-gold-brown: #bf9b5f;
$color-arrows-brown: #c5a37c;
$color-overlay: rgba(255, 255, 255, 0.94);
$color-overlay-70: rgba(255, 255, 255, 0.7);
$color-overlay-75: rgba(255, 255, 255, 0.75);
$color-overlay-dark: rgba(0, 0, 0, 0.5);
$color-overlay-dark-light: rgba(0, 0, 0, 0.3);
$color-shadow: rgba(168, 38, 0, 0.16);
$color-blue-light: #c1e3e4;

// @todo move all to slick file
$color-carousel-active-dot: #fcc2cb;
$color-carousel-inactive-dot: #fee2e6;
$color-carousel-arrow-bg: rgba(253, 194, 203, 0.75);
$color-carousel-arrow-bg-solid: $color-pink-2;
$color-header-background: rgba($color-pink-2, 0.93);

// Product cards style
$color-darker-grey: #222;
$color-darkest-grey: #3a3a3a;
$color-silver-grey: #cacaca;
$color-dusty-grey: #9c9c9c;

// Offer Message Color
$color-success-message: #087443;
$color-error-message: #a80000;
$color-alert-message: #b54708;

// gradient bgs
$bg-white-fade: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 30%);
$bg-gold-fade: linear-gradient(to left bottom, #bf9b5f, #cba973, #d6b887, #e2c79b, #edd6b0);

// fonts
$horizontal-spacing-styleguide: 10px 15px 30px 60px;
$vertical-spacing-styleguide: 10px 20px 30px 40px 60px 120px;
$font--madina-alt: 'Madina Alt' !default;
$font--proxima-nova: 'Proxima Nova' !default;
$font--bauer-bodoni: 'Bauer Bodoni Bold' !default;
$font--gotcha-regular: 'Gotcha Regular' !default;
$font--text: $font--proxima-nova, Arial, sans-serif !default;
$font--heading: $font--bauer-bodoni, 'Times New Roman', serif !default;
$font--helvetica: Helvetica, Arial, sans-serif !default;

$main-font: $font--text;
$base-font-family: $main-font;
$color-navy-blue: #0075db;
$color-dark-red: #cc0000 !default;
$bold-font-family: $font--bauer-bodoni;
$body-font-bold: $bold-font-family;
$color-pink-swan: #b3b3b3;
$color-warm-grey: #777777;
$color-black-light: #191919;
$color-dark-navy: #040a2b;
$lux-error: #dc3530 !default;

$product-grid-padding-x: 7.36%;
// normal 1 col brief dimensions
$product-grid-brief-width: 253;
$product-grid-brief-height: 383;
$product-grid-content-max-width: 1120px;
$color-average: #dc6e10 !default;
$color-good: #dccc10 !default;
$tick: '\2713';
$cross: '\2715';
$color--success: #458745 !default;

// before/after
$color-before-after-gold: #d9c5a4;

$rdirection: right !default;
$ldirection: left !default;
$text-direction: ltr !default;

// Appointment Booking variables
$appt-book-color-gray-field: #575757;
$appt-book-color-gray-border: #b7b7b7;
$appt-book-color-gray-disabled: #bababa;
// Text translations vars
$shade-text: 'Shade:' !default;

$color-very-light-pink: #ebebeb;

// Stacking order (z-index)
$stack-order-loader: 1004;

// Text content
$pr-filter-copy: 'FILTER:';
$pr-sort-copy: 'SORT:';

// *
// * PDP Refresh 2024
// *
$cr24: false !default;
$accordion-mobile-padding: 20px;
$product-full-accordion-max-width: 980px;
$pdp-breadcrumb-lr-padding: 8vw;
