// Common styles used by all icons in HTML and CSS

// TODO: Research
// TODO: Required by mantle, may be able to remove later
@mixin icon($icon: $icon, $pseudo: before, $mq: false, $text-replace: false) {
}

.icon {
  height: 20px;
  width: 20px;
}
